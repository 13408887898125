
<div>
    <div class="search-container">
        <img v-img-prefix :src="`/public-assets/search-white`" alt="search" width="24" height="23">
        <input id="search-input" name="search-input" @keyup.enter="search()"
               v-model="query" type="text" placeholder="Buscador..."/>
    </div>
    <div class="mobile-search-container" v-if="show">
        <SearchContainer :sections="sections" :initialSearch="query" :trending="trending"/>
    </div>
</div>
