import Vue from "vue";
import App from "@/components/search/MenuIndex.vue";

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        render: h => h(App, {
            props: {
                sections: siteSections?.helper,
                trending: trendingSearches,
            }
        })
    }).$mount('#menu-search')
})