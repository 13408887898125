<template>
    <div>
        <div class="search-container">
            <img v-img-prefix :src="`/public-assets/search-white`" alt="search" width="24" height="23">
            <input id="search-input" name="search-input" @keyup.enter="search()"
                   v-model="query" type="text" placeholder="Buscador..."/>
        </div>
        <div class="mobile-search-container" v-if="show">
            <SearchContainer :sections="sections" :initialSearch="query" :trending="trending"/>
        </div>
    </div>
</template>

<script>
import SearchContainer from "@/components/search/SearchContainer.vue";

export default {
    components: {SearchContainer},
    props: {
        sections: Array,
        trending: Array
    },
    data() {
        return {
            show: false,
            query: ''
        }
    },
    mounted() {
        this.$bus.$on('close-search', () => {
            this.show = false
            let body = document.getElementsByTagName('body')[0]
            body.style.overflowY = 'scroll'
        })
    },
    methods: {
        search() {
            if (this.query === '') return
            this.show = true
        }
    }
}
</script>

<style lang="scss" scoped>
.searches-container {
    position: absolute;
    top: 65px;
}
</style>